import { useEffect } from 'react';

const useOnEscape = (callback: () => void) => {
    useEffect(() => {
        const onEscape = (event: KeyboardEvent) => {
            if (event.code === 'Escape') {
                callback();
            }
        };

        document.addEventListener('keydown', onEscape);
        return () => document.removeEventListener('keydown', onEscape);
    }, [callback]);
};

export default useOnEscape;
