import { DayEventHandler } from '@oberoninternal/travelbase-ds/components/calendar/PlannerMonth';
import Period from '@oberoninternal/travelbase-ds/entities/Period';
import { UnreachableCaseError } from '@oberoninternal/travelbase-ds/entities/UnreachableCaseError';
import validInterval from '@oberoninternal/travelbase-ds/utils/validInterval';
import addMonths from 'date-fns/addMonths';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import { useCallback, useMemo, useReducer } from 'react';
import isDate from '../utils/isDate';

export interface DatepickerState {
    period?: Period | Date;
    selecting?: boolean;
    inView: Date[];
    focus?: 'start' | 'end';
    open: boolean;
    autoHide: boolean;
    singleDate?: boolean;
    optionalSingleDate?: boolean;
    isInline: boolean;
}

const getDefaultInitialState = (isInline = false): DatepickerState => ({
    selecting: false,
    period: null,
    inView: isInline ? [new Date()] : [new Date(), addMonths(new Date(), 1)],
    open: false,
    autoHide: true,
    isInline,
});

interface DefaultAction {
    type: 'reset' | 'set' | 'clear';
    state?: Partial<DatepickerState>;
}

interface EventAction {
    type: 'click' | 'hover';
    day: Date;
    focus?: 'start' | 'end';
}

interface ViewEventAction {
    type: 'next' | 'previous';
}

interface SetViewAction {
    type: 'setView';
    month: Date;
}

interface SesameAction {
    type: 'sesame';
    variant: 'open' | 'close' | 'toggle';
}

type Action = DefaultAction | EventAction | ViewEventAction | SetViewAction | SesameAction;

export const MAX_DAYS = 21;

function periodReducer(state: DatepickerState = getDefaultInitialState(), action: Action): DatepickerState {
    const { period, selecting, inView, singleDate, optionalSingleDate, isInline } = state;
    switch (action.type) {
        case 'click': {
            const { day } = action;
            const selectingState: DatepickerState = {
                ...state,
                period: {
                    start: day,
                    end: undefined,
                },
                selecting: true,
                focus: 'end',
            };

            if (singleDate || isDate(period)) {
                return {
                    ...state,
                    period: day,
                    selecting: false,
                    focus: undefined,
                    open: false,
                };
            }

            if (!period) {
                return selectingState;
            }

            if (
                (period.start && isSameDay(period.start, day) && optionalSingleDate && selecting) ||
                (period.end && isSameDay(period.end, day) && state.focus === 'end' && optionalSingleDate && !selecting)
            ) {
                return {
                    ...state,
                    period: { end: day, start: day },
                    focus: 'start',
                    selecting: false,
                };
            }

            if (period.start && isSameDay(period.start, day)) {
                return { ...state, period: undefined, focus: 'start' };
            }

            if (period.end && !selecting && state.focus !== 'start' && isSameDay(period.end, day)) {
                return state;
            }

            if (validInterval(period)) {
                if (state.focus === 'start') {
                    // when the period is about to be invalid (when the focus is end and the day is before the start)
                    // we adapt to this by going to the "selecting" state
                    if (isAfter(day, period.end) || differenceInCalendarDays(period.end, day) > MAX_DAYS) {
                        return selectingState;
                    }

                    if (isSameDay(day, period.end)) {
                        return selectingState;
                    }

                    return {
                        ...state,
                        // selecting: true,
                        period: { ...period, start: day },
                        focus: 'end',
                    };
                }

                if (state.focus === 'end' && !selecting) {
                    if (isAfter(period.start, day)) {
                        return selectingState;
                    }
                    return {
                        ...state,
                        period: { ...state.period, end: day },
                        open: !state.autoHide,
                    };
                }
            }

            return {
                ...state,
                period: { ...period, end: day },
                open: !state.autoHide,
                selecting: false,
            };
        }
        case 'hover': {
            const { day } = action;
            if (selecting && !isDate(period) && period?.start) {
                const [start, end] = [period.start, day];
                return {
                    ...state,
                    period: { start, end },
                };
            }
            return state;
        }
        case 'set':
            return { ...state, ...action.state };
        case 'clear':
            return getDefaultInitialState();
        case 'reset':
            return { ...getDefaultInitialState(), ...action.state };
        case 'next': {
            if (isInline) {
                const [end] = inView;
                return {
                    ...state,
                    inView: [addMonths(end, 1)],
                };
            }
            const [, end] = inView;
            return {
                ...state,
                inView: [end, addMonths(end, 1)],
            };
        }
        case 'previous': {
            if (isInline) {
                const [start] = inView;
                return {
                    ...state,
                    inView: [addMonths(start, -1)],
                };
            }
            const [start] = inView;
            return {
                ...state,
                inView: [addMonths(start, -1), start],
            };
        }
        case 'setView': {
            if (isInline) {
                return {
                    ...state,
                    inView: [addMonths(action.month, 1)],
                };
            }
            return {
                ...state,
                inView: [action.month, addMonths(action.month, 1)],
            };
        }
        case 'sesame': {
            let open = true;
            switch (action.variant) {
                case 'close':
                    open = false;
                    break;
                case 'toggle':
                    open = !state.open;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                open,
            };
        }
        default:
            throw new UnreachableCaseError(action);
    }
}

const useDatepicker = (customInitialState: Partial<DatepickerState>, isInline: boolean) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const initialState = useMemo(
        () => ({ ...getDefaultInitialState(isInline), ...customInitialState }),
        [customInitialState, isInline]
    );
    const [state, dispatch] = useReducer(periodReducer, initialState);

    const set = useCallback((newState: DefaultAction['state']) => {
        dispatch({ state: newState, type: 'set' });
    }, []);

    const reset = useCallback(() => {
        dispatch({ state: initialState, type: 'reset' });
    }, [initialState]);

    const clear = useCallback(() => {
        dispatch({ type: 'clear' });
    }, []);

    const onClick: DayEventHandler = useCallback(({ day }) => dispatch({ type: 'click', day }), []);
    const onHover: DayEventHandler = useCallback(({ day }) => dispatch({ type: 'hover', day }), []);

    const onNextClick = useCallback(() => {
        dispatch({ type: 'next' });
    }, []);
    const onPreviousClick = useCallback(() => {
        dispatch({ type: 'previous' });
    }, []);
    const setView = useCallback((month: Date) => {
        dispatch({ type: 'setView', month });
    }, []);

    const sesame = useCallback((variant: SesameAction['variant']) => dispatch({ type: 'sesame', variant }), []);

    return [
        state,
        {
            onClick,
            onHover,
            reset,
            set,
            clear,
            onNextClick,
            onPreviousClick,
            setView,
            sesame,
        },
    ] as const;
};

export default useDatepicker;

export type DatepickerHook = ReturnType<typeof useDatepicker>;

export type DatepickerActions = DatepickerHook[1];
