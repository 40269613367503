import React, { createContext, FC, useContext } from 'react';
import { MonthDayProps } from '@oberoninternal/travelbase-ds/components/calendar/PlannerMonth';
import { DatepickerHook } from '../../../hooks/useDatepicker';

type TContext = {
    datepicker: DatepickerHook;
    computeDayProps: (day: Date) => MonthDayProps;
    locale: Locale;
    singleDate?: boolean;
    optionalSingleDate?: boolean;
};

const Context = createContext<TContext | undefined>(undefined);

const DatepickerContext: FC<React.PropsWithChildren<{ value: TContext }>> = props => <Context.Provider {...props} />;

export const useDatepickerContext = () => {
    const state = useContext(Context);
    if (!state) {
        throw new Error('useDatepickerContext must be used within a DatepickerContext');
    }

    return state;
};

export default DatepickerContext;
