import Period from '@oberoninternal/travelbase-ds/entities/Period';
import { FieldConfig, FieldHelperProps, FieldMetaProps, useField, useFormikContext } from 'formik';
import React, { FC, useCallback } from 'react';
import parseToDateString from '../constants/parseToDateString';
import Datepicker, { DatepickerProps } from './designsystem/Datepicker';
import { convertToPeriod } from '../utils/trip';
import isDate from '../utils/isDate';

interface DatepickerFieldProps extends DatepickerProps, Omit<FieldConfig, 'name' | 'value'> {
    startName?: string;
    endName?: string;
    // NOTE: if name is provided, startName/endName will be ignored and we'll assume this is a single date field.
    name?: string;
    hasFlexibleDates?: boolean;
}

const performOnChange = (helpers: FieldHelperProps<string>, value: Date | number | undefined) => {
    helpers.setValue(value ? parseToDateString(value) : '');
    setTimeout(() => helpers.setTouched(true));
};

const getError = (meta: FieldMetaProps<string>) => (meta.touched && meta.error ? meta.error : undefined);

const DatepickerField: FC<React.PropsWithChildren<DatepickerFieldProps>> = ({
    startName,
    endName,
    hideInput,
    name,
    ...props
}) => {
    if ((!name && !startName) || (startName && !endName)) {
        throw new Error('Please provide at least either a name or a startName and endName.');
    }

    const [{ value: start }, startMeta, startHelpers] = useField(name ?? (startName as string));
    const [{ value: end }, endMeta, endHelpers] = useField(name ?? (endName as string));
    const { enableReinitialize } = useFormikContext();

    const value = isDate(start) ? new Date(start) : convertToPeriod(start, end);
    const initialValue = convertToPeriod(startMeta.initialValue, endMeta.initialValue);

    const onChange = useCallback(
        (val: Period | Date) => {
            if (isDate(val)) {
                performOnChange(startHelpers, val);
            } else {
                performOnChange(startHelpers, val?.start);
                performOnChange(endHelpers, val?.end);
            }
        },
        [endHelpers, startHelpers]
    );
    return (
        <Datepicker
            // when the initial value changes, we want to reinitialize the datepicker
            key={
                startMeta.initialValue && endMeta.initialValue && enableReinitialize
                    ? `${startMeta.initialValue}-${endMeta.initialValue}`
                    : undefined
            }
            hideInput={hideInput}
            defaultValue={initialValue}
            value={value}
            onChange={onChange}
            error={getError(startMeta) ?? getError(endMeta)}
            singleDate={!!name}
            {...props}
        />
    );
};

export default DatepickerField;
