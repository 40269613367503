import { MonthDayProps } from '@oberoninternal/travelbase-ds/components/calendar/PlannerMonth';
import ErrorMessage from '@oberoninternal/travelbase-ds/components/form/ErrorMessage';
import { useDeviceSize } from '@oberoninternal/travelbase-ds/context/devicesize';
import Period from '@oberoninternal/travelbase-ds/entities/Period';
import validInterval from '@oberoninternal/travelbase-ds/utils/validInterval';
import { Box } from '@rebass/grid';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfToday from 'date-fns/startOfToday';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import parseToDateString from '../../constants/parseToDateString';
import { Locale } from '../../entities/Locale';
import useDatepicker, { DatepickerState, MAX_DAYS } from '../../hooks/useDatepicker';
import useOnEscape from '../../hooks/useOnEscape';
import usePrevious from '../../hooks/usePrevious';
import isDate from '../../utils/isDate';
import { getDateFnsLocale } from '../../utils/localeHelpers';
import DatepickerContext from './datepicker/Context';
import DatepickerInputs from './datepicker/Inputs';
import Inline from './datepicker/Inline';
import useOnClickOutside from 'use-onclickoutside';
import Modal from './datepicker/DatepickerModal';
import styled, { css } from 'styled-components';
import { StyledMonth } from './datepicker/Month';

export const createDayModifier = (state: DatepickerState) => {
    const { period, selecting, focus } = state;

    return (day: Date): MonthDayProps => {
        if (isBefore(day, startOfToday())) {
            return { variant: 'disabled' };
        }

        if (!isDate(period)) {
            const blocked =
                period?.start &&
                (differenceInCalendarDays(day, period.start) > MAX_DAYS || isBefore(day, period.start));
            const { start, end } = period ?? {};
            if (start && end && isSameDay(day, end) && isSameDay(day, start)) {
                return { variant: 'selected' };
            }
            if (start && isSameDay(day, start)) {
                return { variant: 'selected-start' };
            }
            if (start && end && isSameDay(day, end)) {
                return {
                    variant: 'selected-end',
                };
            }

            if ((selecting || focus === 'end') && blocked) {
                return { variant: 'blocked' };
            }

            if (validInterval(period) && isWithinInterval(day, period)) {
                return {
                    variant: 'bookable-within',
                };
            }
        } else if (isSameDay(period, day)) {
            return {
                variant: 'selected',
            };
        }

        return { variant: 'bookable' };
    };
};

export interface DatepickerProps {
    defaultValue?: Period | Date;
    value?: Period | Date;
    onChange?: (value: Period | Date) => void;
    className?: string;
    scrollIntoView?: boolean;
    error?: string;
    align?: 'left' | 'right' | 'center';
    hideCalendarIcon?: boolean;
    // if passed true, we'll be emitting dates, not periods.
    singleDate?: boolean;
    optionalSingleDate?: boolean;
    hideHelper?: boolean;
    hideInput?: boolean;
    hasFlexibleDates?: boolean;
    datepicker?: ReturnType<typeof useDatepicker>;
    ignoreClickOutside?: boolean;
    variant?: 'field' | 'inline';
}

const periodsAreEqual = (a: Period, b: Period) =>
    validInterval(a) && validInterval(b) ? isSameDay(a.start, b.start) && isSameDay(a.end, b.end) : a === b;

const periodsDiffer = (a: Period | Date, b: { current: Period | Date }) =>
    isDate(a) || isDate(b.current) ? !isSameDay(a as Date, b.current as Date) : !periodsAreEqual(a, b.current);

const Datepicker: FC<React.PropsWithChildren<DatepickerProps>> = ({
    onChange,
    value,
    defaultValue,
    className,
    scrollIntoView,
    error,
    align,
    hideCalendarIcon = false,
    singleDate,
    optionalSingleDate,
    hideHelper,
    children,
    hideInput,
    hasFlexibleDates,
    datepicker: customDatepicker,
    ignoreClickOutside,
    variant = 'field',
}) => {
    const internalDatepicker = useDatepicker(
        {
            singleDate,
            optionalSingleDate,
            period: defaultValue,
            open: false,
        },
        variant === 'inline'
    );
    const datepicker = customDatepicker ?? internalDatepicker;
    const [state, { set, sesame, reset }] = datepicker;
    const deviceSize = useDeviceSize();
    const { open, period, selecting } = state;
    const router = useRouter();

    const computeDayProps = useMemo(() => createDayModifier(state), [state]);
    const previousPeriod = useRef(period);
    const dateLocale = getDateFnsLocale(router.locale as Locale);

    // when out of sync, perform an onChange
    useEffect(() => {
        if (!onChange || selecting || value === period) {
            return;
        }

        if (periodsDiffer(period, previousPeriod)) {
            onChange(period);
            previousPeriod.current = period;
        }
    }, [onChange, period, selecting, value]);

    const prevValue = useRef(value);
    useEffect(() => {
        if (periodsDiffer(value, prevValue)) {
            set({ period: value });
            prevValue.current = value;
        }
    }, [period, set, value]);

    const previousOpen = usePrevious(open);

    // Scroll to the first date if there's a period selected and the modal is going to open;
    useEffect(() => {
        if (deviceSize === 'mobile' && !isDate(period) && validInterval(period) && !previousOpen && open) {
            setTimeout(() => {
                if (period?.start) {
                    const dayEl = document.getElementById(parseToDateString(period.start));
                    dayEl?.scrollIntoView({ block: 'center' });
                }
            });
        }
    }, [open, previousOpen, period, deviceSize]);

    const containerRef = useRef<HTMLDivElement>(null);

    const onCancel = () => {
        if (selecting) {
            reset();
        } else if (open) {
            sesame('close');
        }
    };
    useOnClickOutside(
        containerRef,
        ignoreClickOutside
            ? () => {
                  // no op
              }
            : onCancel
    );
    useOnEscape(onCancel);

    const context = useMemo(
        () => ({ datepicker, computeDayProps, locale: dateLocale, singleDate }),
        [computeDayProps, datepicker, dateLocale, singleDate]
    );

    return (
        <DatepickerContext value={context}>
            <Box
                ref={deviceSize !== 'mobile' ? containerRef : undefined}
                style={{ position: 'relative', height: '100%' }}
                className={className}
            >
                {children ?? <DatepickerInputs hideInput={hideInput} hideCalendarIcon={hideCalendarIcon} />}
                {(variant === 'inline' || (variant === 'field' && deviceSize !== 'mobile')) && (
                    <StyledInline
                        hideInput={hideInput}
                        align={align}
                        scrollIntoView={scrollIntoView}
                        hasFlexibleDates={hasFlexibleDates}
                        forceOpen={variant === 'inline'}
                        isField={variant === 'field'}
                    />
                )}
                {error && <ErrorMessage>{error}</ErrorMessage>}
            </Box>

            {variant === 'field' && deviceSize === 'mobile' && (
                <Modal hideHelper={hideHelper} hasFlexibleDates={hasFlexibleDates} />
            )}
        </DatepickerContext>
    );
};

const StyledInline = styled(Inline)<{ isField: boolean }>`
    ${({ isField }) =>
        isField
            ? css`
                  @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
                      padding: 3.2rem 2.4rem;
                      margin-top: 1.6rem;
                      min-width: 68rem;
                  }
              `
            : css`
                  ${StyledMonth} {
                      @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
                          width: 100%;
                          height: unset;
                          padding: 0;
                      }
                  }
              `}
`;

export default Datepicker;
