import ArrowLeft from '@oberoninternal/travelbase-ds/components/figure/ArrowLeft';
import ArrowRight from '@oberoninternal/travelbase-ds/components/figure/ArrowRight';
import { UnreachableCaseError } from '@oberoninternal/travelbase-ds/entities/UnreachableCaseError';
import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';
import isSameMonth from 'date-fns/isSameMonth';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import useScrollIntoView from '../../../hooks/useScrollIntoView';
import isDate from '../../../utils/isDate';
import { useDatepickerContext } from './Context';
import Month, { StyledMonth } from './Month';

type Animation = 'previous' | 'next' | null;

interface Props {
    scrollIntoView?: boolean;
    align?: 'left' | 'right' | 'center';
    hideInput?: boolean;
    hasFlexibleDates?: boolean;
    forceOpen?: boolean;
    className?: string;
}

const GAP = 16;

const Inline: FC<React.PropsWithChildren<Props>> = ({
    scrollIntoView = false,
    align = 'right',
    hideInput = false,
    hasFlexibleDates = false,
    forceOpen = false,
    className,
}) => {
    const {
        datepicker: [
            { inView, selecting, period, open: datePickerStateOpen },
            { onNextClick, onPreviousClick, onClick, onHover },
        ],
        computeDayProps,
        locale,
    } = useDatepickerContext();
    const open = forceOpen ? true : datePickerStateOpen;

    const monthsRef = useRef<HTMLDivElement>(null);

    const getMonthDimensions = useCallback((index = 0) => {
        const monthEl = monthsRef.current?.firstChild?.childNodes?.[index] as HTMLDivElement | undefined;
        return {
            width: monthEl ? monthEl.clientWidth + GAP : 0,
            height: monthEl ? Math.ceil(monthEl.clientHeight) : 0,
        };
    }, []);

    const [maxHeight, setMaxHeight] = useState(0);
    const [animate, setAnimate] = useState<Animation>(null);

    const onAnimationEndHandler = useCallback(() => {
        if (animate === 'next') {
            onNextClick();
        }
        if (animate === 'previous') {
            onPreviousClick();
        }

        setAnimate(null);
    }, [animate, onNextClick, onPreviousClick]);

    const getSortedMonthDimensionsForRange = useCallback(
        (indexA: number, indexB: number) =>
            [getMonthDimensions(indexA).height, getMonthDimensions(indexB).height].sort((a, b) => b - a)[0],
        [getMonthDimensions]
    );

    const onAnimationStartHandler = useCallback(() => {
        if (animate === 'next') {
            const tallestHeight = getSortedMonthDimensionsForRange(2, 3);
            setMaxHeight(tallestHeight);
        }
        if (animate === 'previous') {
            const tallestHeight = getSortedMonthDimensionsForRange(0, 1);
            setMaxHeight(tallestHeight);
        }
    }, [animate, getSortedMonthDimensionsForRange]);

    useEffect(() => {
        if (monthsRef.current) {
            const monthWidth = getMonthDimensions().width;
            monthsRef.current.scrollLeft = monthWidth;
        }
    }, [getMonthDimensions]);

    useScrollIntoView(monthsRef, scrollIntoView && open, scrollIntoView && !open, { block: 'center' });

    useEffect(() => {
        const tallestHeight = getSortedMonthDimensionsForRange(1, 2);
        setMaxHeight(tallestHeight);
    }, [getSortedMonthDimensionsForRange, open]);

    const [end] = inView;

    // We render 4 months, from which the two in the middle are in view
    const months = [...inView];

    return (
        <Container
            maxheight={maxHeight}
            open={open}
            align={align}
            hideInput={hideInput}
            hasFlexibleDates={hasFlexibleDates}
            className={className}
        >
            {!isSameMonth(inView[0], new Date()) && (
                <>
                    <LeftMobileButton
                        disabled={isSameMonth(inView[0], new Date())}
                        onClick={() => setAnimate('previous')}
                        type="button"
                    >
                        <ArrowLeft />
                    </LeftMobileButton>
                </>
            )}
            <MonthContainer ref={monthsRef}>
                <Months
                    translateValue={getMonthDimensions().width}
                    animate={animate}
                    onAnimationEnd={onAnimationEndHandler}
                    onAnimationStart={onAnimationStartHandler}
                >
                    {months.map(monthDate => (
                        <Month
                            locale={locale}
                            key={+monthDate}
                            isSelecting={selecting}
                            onDayMouseEnter={onHover}
                            onDayClick={onClick}
                            period={isDate(period) ? { start: period, end: addDays(period, 1) } : period}
                            date={monthDate}
                            computeDayProps={computeDayProps}
                        />
                    ))}
                </Months>
            </MonthContainer>

            <RightMobileButton
                disabled={isSameMonth(addYears(new Date(), 2), end)}
                onClick={() => setAnimate('next')}
                type="button"
            >
                <ArrowRight />
            </RightMobileButton>
        </Container>
    );
};

export default Inline;

const LeftMobileButton = styled.button`
    width: 2.4rem;
    height: 2.4rem;
    appearance: none;
    background: none;
    border: none;
    position: absolute;
    left: 1.6rem;
    top: 1.6rem;
    z-index: 1111;

    ${({ disabled }) => disabled && 'opacity: .6;'}
`;

const RightMobileButton = styled(LeftMobileButton)`
    left: unset;
    right: 1.6rem;
`;

const Months = styled.div<{ translateValue: number; animate: Animation }>`
    display: flex;
    width: 100%;

    @keyframes slideRight {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(${({ translateValue }) => translateValue * -1}px);
        }
    }

    @keyframes slideLeft {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(${({ translateValue }) => translateValue}px);
        }
    }

    ${({ animate }) => animate && `animation: 250ms ease-out ${animate === 'next' ? 'slideRight' : 'slideLeft'}`};

    @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.s - 1}px) {
        // > ${StyledMonth} + ${StyledMonth} {
        //     margin-top: ${GAP}px;
        // }
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        > ${StyledMonth} + ${StyledMonth} {
            margin-left: ${GAP}px;
        }
    }
`;

const getAlignment = (align: 'left' | 'center' | 'right') => {
    switch (align) {
        case 'left':
            return css`
                left: 0;
            `;
        case 'right':
            return css`
                right: 0;
            `;
        case 'center':
            return css`
                left: 25%;
            `;
        default:
            throw new UnreachableCaseError(align);
    }
};

interface ContainerProps {
    open: boolean | null;
    maxheight: number;
    align: 'left' | 'center' | 'right';
    hideInput?: boolean;
    hasFlexibleDates?: boolean;
}

export const Container = styled.div<ContainerProps>`
    ${({ maxheight }) => maxheight && `max-height: calc(${maxheight}px + 6.4rem)`};
    transition: max-height 300ms;
    display: flex;
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    background: white;
    max-width: 100%;
    flex-wrap: wrap;
    border-radius: 0.8rem;
    padding: 1.6rem;
    margin-top: 0;
    z-index: ${({ theme }) => theme.zIndices.dropdown};
    position: absolute;
    ${({ hideInput }) => (hideInput ? `position: relative;` : `box-shadow: 0 0 40px 0 rgba(16, 36, 48, 0.1);`)};
    ${({ align }) => getAlignment(align)}
`;

const MonthContainer = styled.div`
    overflow-x: hidden;
    width: 100%;
`;
