import styled, { css } from 'styled-components';
import TextInput from '@oberoninternal/travelbase-ds/components/form/TextInput';
import createBorderCss from '@oberoninternal/travelbase-ds/utils/createBorderCss';
import React, { FC } from 'react';
import Calendar from '../../svg/Calendar.svg';
import { useDatepickerContext } from './Context';
import isDate from '../../../utils/isDate';
import { useIntl } from 'react-intl';
import addMonths from 'date-fns/addMonths';

interface Props {
    hideCalendarIcon?: boolean;
    hideInput?: boolean;
}

const DatepickerInputs: FC<React.PropsWithChildren<Props>> = ({ hideCalendarIcon, hideInput }) => {
    const {
        datepicker: [{ open, period, inView, selecting, ...state }, { sesame, set }],
        singleDate,
    } = useDatepickerContext();
    const { formatDate, formatMessage } = useIntl();
    const formatToValue = (date: Date | number | undefined) =>
        date ? formatDate(date, { format: 'DATERANGEPICKER' }) : '';
    const placeholderMessage = formatMessage({ defaultMessage: 'Selecteer' });

    const start = isDate(period) ? period : period?.start;
    const onClickInput = (focus: 'start' | 'end') => {
        set({
            inView: start ? [new Date(start), addMonths(start, 1)] : inView,
            open: true,
            focus,
        });
    };
    const onClickStart = () => onClickInput('start');
    const onClickEnd = () => onClickInput(!isDate(period) && period?.start ? 'end' : 'start');

    return (
        <InputWrapper
            hideCalendarIcon={hideCalendarIcon}
            singleDate={singleDate}
            hasFocus={open && state.focus === 'start'}
            data-cy="datePicker"
        >
            <DatepickerStartInput
                iconClickHandler={() => {
                    sesame('toggle');
                }}
                onClick={onClickStart}
                hasFocus={open && state.focus === 'start'}
                placeholder={placeholderMessage}
                value={isDate(period) ? formatToValue(period) : formatToValue(period?.start)}
                readOnly
                hideInput={hideInput}
                data-cy="datePickerStart"
            >
                {!hideCalendarIcon && !hideInput && <Calendar />}
            </DatepickerStartInput>
            {!singleDate && (
                <DatepickerEndInput
                    hasFocus={open && state.focus === 'end'}
                    onClick={onClickEnd}
                    placeholder={placeholderMessage}
                    value={!selecting && !isDate(period) ? formatToValue(period?.end) : ''}
                    readOnly
                    hideInput={hideInput}
                    data-cy="datePickerEnd"
                />
            )}
        </InputWrapper>
    );
};

export default DatepickerInputs;

export const InputWrapper = styled.div<{ hasFocus: boolean; singleDate?: boolean; hideCalendarIcon?: boolean }>`
    width: 100%;
    display: flex;
    > div:first-of-type {
        :hover,
        :focus-within {
            z-index: 1;
        }
        ${({ hasFocus }) => hasFocus && 'z-index: 1'};
        flex: unset;
        width: ${({ singleDate }) => (singleDate ? '100%' : 'calc(50% + 1.2rem)')};

        ${({ hideCalendarIcon }) =>
            !hideCalendarIcon &&
            css`
                input {
                    padding-left: 4.2rem !important;
                }
            `};
    }

    > div:last-of-type:first-of-type > input {
        border-radius: 0.8rem;
    }
`;

export const StyledTextInput = styled(TextInput)<{ hasFocus?: boolean }>`
    && {
        z-index: 1;
        ${({ hasFocus, theme }) => hasFocus && `--border-color: ${theme.colors.secondary['20']}`};
        :hover {
            --border-color: ${({ theme, hasFocus }) =>
                hasFocus ? theme.colors.secondary['30'] : theme.colors.neutral['30']};
        }
    }
    color: currentColor;
    height: 4.8rem;
    ${createBorderCss('1px')};
`;

export const DatepickerStartInput = styled(StyledTextInput)<{ hideInput?: boolean }>`
    border-radius: ${({ theme }) => theme.radius.textInput} 0 0 ${({ theme }) => theme.radius.textInput};
    padding-right: ${({ theme }) => theme.spacing['30_Small']};
    ${({ hideInput }) => hideInput && `display: none;`}
`;

export const DatepickerEndInput = styled(StyledTextInput)<{ hideInput?: boolean }>`
    border-radius: 0 ${({ theme }) => theme.radius.textInput} ${({ theme }) => theme.radius.textInput} 0;
    ${({ hideInput }) => hideInput && `display: none;`}
`;
