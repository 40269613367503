import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { FormattedMessage } from 'react-intl';
import { Box, Flex } from '@rebass/grid';
import { StepperField } from '@oberoninternal/travelbase-ds/components/form/Stepper';
import Carousel from './designsystem/Carousel';
import MonthSelect from './MonthSelect';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { SearchBarValues } from './SearchBox';
import parse from 'date-fns/parse';
import endOfMonth from 'date-fns/endOfMonth';
import differenceInDays from 'date-fns/differenceInDays';
import startOfMonth from 'date-fns/startOfMonth';
import Switch from '@oberoninternal/travelbase-ds/components/form/Switch';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';

const FlexibleDateContent: FC<React.PropsWithChildren<unknown>> = () => {
    const { values, setFieldValue } = useFormikContext<SearchBarValues>();
    const parsedFlexibleMonth = useMemo(
        () => values.booking.flexibleMonth && parse(values.booking.flexibleMonth, 'MM-yyyy', new Date()),
        [values]
    );
    const maxDays = useMemo(
        () =>
            parsedFlexibleMonth
                ? differenceInDays(endOfMonth(parsedFlexibleMonth), startOfMonth(parsedFlexibleMonth))
                : 31,
        [parsedFlexibleMonth]
    );

    return (
        <>
            <FieldWrapper py={16} width={['100%', '32.5rem']}>
                <StyledTitle variant="small">
                    <FormattedMessage defaultMessage="Hoeveel nachten wil je blijven?" />
                </StyledTitle>
                <Flex alignItems="center" justifyContent="center">
                    <StepperField
                        validate={() => {
                            // override validator because error message is ugly
                        }}
                        minimum={1}
                        maximum={maxDays}
                        name="booking.amountOfNights"
                    />
                </Flex>
            </FieldWrapper>
            <FieldWrapper pt={16}>
                <StyledTitle variant="small">
                    <FormattedMessage defaultMessage="In welke maand wil je zoeken?" />
                </StyledTitle>
                <Box pl={[4]} pt={20} width="100%">
                    <CarouselWrapper>
                        <Carousel buttonSize="small" continuous arrowsOnObject>
                            <MonthSelect />
                        </Carousel>
                    </CarouselWrapper>
                </Box>
                <Flex alignItems="center" justifyContent="center">
                    <Switch
                        checked={values.booking.type === 'flexible-weekends'}
                        onChange={e =>
                            setFieldValue(
                                'booking.type',
                                e.currentTarget.checked ? 'flexible-weekends' : 'flexible-random'
                            )
                        }
                        id="flexible-weekends-switch"
                    />
                    <Label htmlFor="flexible-weekends-switch" variant="small" style={{ marginLeft: '0.8rem' }}>
                        <FormattedMessage defaultMessage="Alleen in het weekend zoeken" />
                    </Label>
                </Flex>
            </FieldWrapper>
        </>
    );
};

export default FlexibleDateContent;

const FieldWrapper = styled(Box)`
    margin: 0 auto;
`;

const CarouselWrapper = styled.div`
    position: relative;
`;

const StyledTitle = styled(Title)`
    text-align: center;
`;
