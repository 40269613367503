import Modal from '@oberoninternal/travelbase-ds/components/layout/Modal';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Period from '@oberoninternal/travelbase-ds/entities/Period';
import validInterval from '@oberoninternal/travelbase-ds/utils/validInterval';
import { Box, Flex } from '@rebass/grid';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import React, { FC, ReactNode, useCallback, useRef, useState } from 'react';
import InView from 'react-intersection-observer';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { config } from 'react-spring';
import styled, { css } from 'styled-components';
import { useTenantContext } from '../../../context/TenantContext';
import isDate from '../../../utils/isDate';
import StickyBar from '../../StickyBar';
import { useDatepickerContext } from './Context';
import Month from './Month';
import ToggleSwitchWithText from '@oberoninternal/travelbase-ds/components/form/ToggleSwitchWithText';
import FlexibleDateContent from '../../FlexibleDateContent';
import { useFormikContext } from 'formik';
import { SearchBarValues } from '../../SearchBox';

interface Props {
    hideHelper?: boolean;
    helperChildren?: ReactNode;
    hasFlexibleDates?: boolean;
}

const getInitialMonthsAmount = (period: Period | Date) => {
    if (isDate(period)) {
        return period.getMonth() + 1;
    }
    if (period?.start) {
        return new Date(period.start).getMonth() + 1;
    }
    return 3;
};

const getHelpMessage = (intl: IntlShape, period: Period | Date, singleDate: boolean) => {
    if (singleDate) {
        return intl.formatMessage({ defaultMessage: 'Kies een datum' });
    }
    if (!period) {
        return intl.formatMessage({ defaultMessage: 'Kies je aankomstdatum' });
    }

    if (!isDate(period) && validInterval(period)) {
        return intl.formatMessage(
            { defaultMessage: 'Van {startDate} t/m {endDate}' },
            {
                startDate: intl.formatDate(period.start, { format: 'WEEKDAYMONTH' }),
                endDate: intl.formatDate(period.end, { format: 'WEEKDAYMONTH' }),
            }
        );
    }

    return intl.formatMessage({ defaultMessage: 'Kies je vertrekdatum' });
};

const DatepickerModal: FC<React.PropsWithChildren<Props>> = ({
    hideHelper = false,
    helperChildren,
    hasFlexibleDates,
}) => {
    const {
        datepicker: [{ open, period, selecting }, { onClick, onHover, sesame }],
        computeDayProps,
        locale,
        singleDate = false,
    } = useDatepickerContext();
    const [monthsAmount, setMonthsAmount] = useState(() => getInitialMonthsAmount(period));
    const maximumReached = differenceInCalendarMonths(addMonths(new Date(), monthsAmount), new Date()) <= 24;
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { brandConfig } = useTenantContext();
    const onChange = useCallback(
        (inView: boolean, index: number) => {
            if (inView && index === monthsAmount - 1 && maximumReached) {
                setMonthsAmount(prev => prev + 1);
            }
        },
        [maximumReached, monthsAmount]
    );
    const intl = useIntl();
    const helpMessage = hideHelper ? null : getHelpMessage(intl, period, singleDate);
    const { values, setFieldValue } = useFormikContext<SearchBarValues>();
    const helperContent = (
        <StyledStickyBar open={open} wrapInPortal>
            <Flex justifyContent="center" alignItems="center" width={1} flexDirection="column">
                {helpMessage && <Body variant="small">{helpMessage}</Body>}
                {helperChildren && <>{helperChildren}</>}
            </Flex>
        </StyledStickyBar>
    );

    return (
        <>
            <StyledModal
                config={{ ...config.gentle, duration: 200 }}
                open={open}
                onClose={() => {
                    sesame('close');
                }}
            >
                <Container
                    hideScrollbar={brandConfig.searchBox?.hideScrollbar}
                    ref={containerRef}
                    style={{ overflow: 'auto', height: '100%' }}
                >
                    {hasFlexibleDates ? (
                        <>
                            <ToggleSwitchWrapper px={[0, 0, 16]}>
                                <ToggleSwitchWithText
                                    name="dateSwitchMobile"
                                    active={values.booking.type === 'static' ? 'static-mobile' : 'flexible-mobile'}
                                    setActive={key =>
                                        setFieldValue(
                                            'booking.type',
                                            key === 'flexible-mobile' ? 'flexible-random' : 'static'
                                        )
                                    }
                                    options={[
                                        {
                                            key: 'static-mobile',
                                            label: <FormattedMessage defaultMessage="Datums kiezen" />,
                                        },
                                        {
                                            key: 'flexible-mobile',
                                            label: <FormattedMessage defaultMessage="Flexibel zoeken" />,
                                        },
                                    ]}
                                />
                            </ToggleSwitchWrapper>
                            <>
                                {values?.booking?.type === 'static' &&
                                    monthsAmount &&
                                    new Array(monthsAmount).fill(null).map((_, i) => {
                                        const correctedPeriod = isDate(period)
                                            ? { start: period, end: addDays(period, 1) }
                                            : period;
                                        return (
                                            <InView
                                                key={i}
                                                threshold={0.3}
                                                triggerOnce
                                                onChange={inView => onChange(inView, i)}
                                            >
                                                {({ ref }) => (
                                                    <Month
                                                        isSelecting={selecting}
                                                        onDayMouseEnter={onHover}
                                                        onDayClick={onClick}
                                                        period={correctedPeriod}
                                                        date={addMonths(new Date(), i)}
                                                        computeDayProps={computeDayProps}
                                                        ref={ref}
                                                        locale={locale}
                                                    />
                                                )}
                                            </InView>
                                        );
                                    })}
                                {values?.booking?.type !== 'static' && <FlexibleDateContent />}
                            </>
                        </>
                    ) : (
                        <>
                            {monthsAmount &&
                                new Array(monthsAmount).fill(null).map((_, i) => {
                                    const correctedPeriod = isDate(period)
                                        ? { start: period, end: addDays(period, 1) }
                                        : period;
                                    return (
                                        <InView
                                            key={i}
                                            threshold={0.3}
                                            triggerOnce
                                            onChange={inView => onChange(inView, i)}
                                        >
                                            {({ ref }) => (
                                                <Month
                                                    isSelecting={selecting}
                                                    onDayMouseEnter={onHover}
                                                    onDayClick={onClick}
                                                    period={correctedPeriod}
                                                    date={addMonths(new Date(), i)}
                                                    computeDayProps={computeDayProps}
                                                    ref={ref}
                                                    locale={locale}
                                                />
                                            )}
                                        </InView>
                                    );
                                })}
                        </>
                    )}
                </Container>
            </StyledModal>
            {!hideHelper && (hasFlexibleDates ? values?.booking?.type === 'static' && helperContent : helperContent)}
        </>
    );
};

export default DatepickerModal;

const StyledStickyBar = styled(StickyBar)<{ open: boolean }>`
    transform: translateY(${({ open }) => (open ? '0px' : '100%')});
    transition: transform 200ms ease-in-out;
    padding-top: 2rem;
    padding-bottom: 2rem;
`;

const StyledModal = styled(Modal)`
    [data-reach-dialog-content] {
        position: relative;
        overflow: unset;
        padding: 1.6rem 0.8rem 0 0.8rem;
    }
`;

const Container = styled.div<{ hideScrollbar?: boolean }>`
    ${({ hideScrollbar }) =>
        hideScrollbar &&
        css`
            -webkit-overflow-scrolling: touch;
            &::-webkit-scrollbar {
                display: none;
            }
            scrollbar-width: 0;
            /* hide scrollbar on safari */
            -ms-overflow-style: none;
        `};

    @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.s + 1}px) {
        flex: 1;
    }
`;

const ToggleSwitchWrapper = styled(Box)`
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 1.6rem;
`;
