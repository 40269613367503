import PlannerMonth, {
    // PlannerMonthProps,
    PlannerWeek,
} from '@oberoninternal/travelbase-ds/components/calendar/PlannerMonth';
// import propsAreEqual from '@oberoninternal/travelbase-ds/utils/propsAreEqual';
// import { BoxProps } from '@rebass/grid';
import { memo } from 'react';
import styled from 'styled-components';
// import { areSamePeriods } from '../../../utils/planner';

export const StyledMonth = styled(PlannerMonth)`
    width: 100%;

    ${PlannerWeek}:first-of-type {
        position: sticky;
        background: white;
        top: 2rem;
        z-index: 10;
    }

    > div:first-of-type {
        position: sticky;
        background: white;
        top: 0;
        z-index: 11;
        margin-bottom: 0;
        padding: 0;
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        width: calc(50% - 0.8rem);
        height: 100%;
        padding: 8px 0;
    }
`;

const Month = memo(
    StyledMonth
    // TODO: fix this
    // propsAreEqual<Omit<PlannerMonthProps, keyof BoxProps>>({
    //     date: (prev: any, next: any) => +prev === +next,
    //     period: areSamePeriods,
    //     onDayClick: 'skip',
    //     onDayMouseEnter: 'skip',
    //     onDayMouseLeave: 'skip',
    // })
);

export default Month;
