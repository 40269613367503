import React, { FC } from 'react';
import add from 'date-fns/add';
import format from 'date-fns/format';
import RadioLabel from '@oberoninternal/travelbase-ds/components/form/RadioLabel';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import getYear from 'date-fns/getYear';
import { useFormikContext } from 'formik';
import { SearchBarValues } from './SearchBox';
import { useIntl } from 'react-intl';

const getTheNextMonths = () => {
    const now = new Date();
    const months = [
        {
            name: format(new Date(), 'dd MMMM yyyy'),
            id: format(new Date(), 'MM-yyyy'),
            date: now,
        },
    ];
    for (let i = 0; i <= 23; i += 1) {
        const date = add(new Date(), { months: i + 1 });
        months.push({
            name: format(date, 'MMMM yyyy'),
            id: format(date, 'MM-yyyy'),
            date,
        });
    }
    return months;
};

const MonthSelect: FC<React.PropsWithChildren<unknown>> = () => {
    const months = getTheNextMonths();
    const { values, setFieldValue } = useFormikContext<SearchBarValues>();
    const { formatDate } = useIntl();
    return (
        <>
            {months.map(month => {
                const monthName = formatDate(month.date, { month: 'long' });
                const yearName = getYear(month.date);
                return (
                    <Month key={month.id}>
                        <MonthRadio
                            required
                            checked={values.booking.flexibleMonth === month.id}
                            onChange={() => setFieldValue('booking.flexibleMonth', month.id)}
                            name="month"
                            id={month.id}
                            variant="primary"
                        >
                            <MonthWrapper flexDirection="column" justifyContent="center" alignItems="center">
                                <MonthLabel>{monthName}</MonthLabel>
                                {yearName}
                            </MonthWrapper>
                        </MonthRadio>
                    </Month>
                );
            })}
        </>
    );
};

export default MonthSelect;

const Month = styled.div`
    scroll-snap-align: start;
    position: relative;
    overflow: hidden;

    & + & {
        margin-left: 1.6rem;
    }
`;

const MonthRadio = styled(RadioLabel)`
    label {
        border-radius: 8px !important;
        padding: 1.6rem 0.9rem !important;
        min-width: 12rem;
        min-height: 12rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const MonthLabel = styled.span`
    font-weight: bold;
`;

const MonthWrapper = styled(Flex)``;
